import { Controller } from '@hotwired/stimulus'
import { createConsumer } from '@rails/actioncable'
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = [
    'form',
    'modal',
    'checkbox',
    'toggleSwitch',
    'fileName',
    'fileInputWrapper',
    'slider',
    'advancedSettings',
    'advancedSettingsIcon',
    'searchIdentityCheckbox',
    'searchIdentityToggleSwitch',
    'audioIntelligenceCheckbox',
    'audioIntelligenceToggleSwitch',
    'detectsList',
    'advancedSettingsInputs',
    'videoSettings',
    'llmCheckbox',
    'llmToggleSwitch',
    'videoSettingsMessage',
    'videoSettingsContent',
    'videoSettingsInputs',
  ]
  settingsChanged = {
    frame_length: false,
    sensitivity: false,
    isolate_voice: false,
    search_identity: false,
    audio_intelligence: false,
    start_region: false,
    end_region: false,
    max_video_fps: false,
    max_video_secs: false,
    use_llm: false,
  }

  pendingUpdates: { [key: string]: { identitySearch?: any; audioIntelligence?: any } } = {}
  expectedResults: { [key: string]: { identitySearch: boolean; audioIntelligence: boolean } } = {}
  filename: string | null = null
  audio_url: string | null = null

  declare readonly fileNameTarget: HTMLSpanElement
  declare readonly fileInputWrapperTarget: HTMLDivElement
  declare readonly modalTarget: HTMLDivElement
  declare readonly checkboxTarget: HTMLInputElement
  declare readonly formTarget: HTMLFormElement
  declare readonly toggleSwitchTarget: HTMLDivElement
  declare readonly advancedSettingsTarget: HTMLDivElement
  declare readonly advancedSettingsIconTarget: HTMLElement
  declare readonly searchIdentityCheckboxTarget: HTMLInputElement
  declare readonly searchIdentityToggleSwitchTarget: HTMLDivElement
  declare readonly audioIntelligenceCheckboxTarget: HTMLInputElement
  declare readonly audioIntelligenceToggleSwitchTarget: HTMLDivElement
  declare readonly detectsListTarget: HTMLUListElement
  declare readonly advancedSettingsInputsTargets: HTMLElement[]
  declare readonly videoSettingsTarget: HTMLElement
  declare readonly videoSettingsMessageTarget: HTMLElement
  declare readonly videoSettingsContentTarget: HTMLElement
  declare readonly videoSettingsInputsTargets: HTMLElement[]
  declare readonly llmCheckboxTarget: HTMLInputElement
  declare readonly llmToggleSwitchTarget: HTMLElement

  private channel: any

  connect(): void {
    this.channel = createConsumer().subscriptions.create('DetectChannel', {
      connected() {
        // Called when the subscription is ready for use on the server
      },
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
      received: (data: any) => {
        // Called when there's incoming data on the websocket for this channel
        this.handleWebSocketMessage(data)
      },
    })

    this.advancedSettingsTarget.querySelectorAll('input').forEach((input) => {
      input.addEventListener('change', () => {
        const key = input.name as keyof typeof this.settingsChanged
        this.settingsChanged[key] = true
      })
    })
  }

  handleWebSocketMessage(data: { action: string; detect?: any; detect_id?: string; identity_search?: any; audio_intelligence?: any }) {
    switch (data.action) {
      case 'update_detect': {
        if (data.detect) {
          this.updateDetectUI(data.detect)
        }
        break
      }
      case 'update_identity_search': {
        if (data.detect_id && data.identity_search) {
          const detectElement = document.getElementById(`detect-${data.detect_id}`)
          if (detectElement) {
            const detect = JSON.parse(detectElement.dataset.detect || '{}')
            detect.identity_search = data.identity_search
            this.updateDetectUI(detect)

            // Clear expectation for identity search
            if (this.expectedResults[data.detect_id]) {
              this.expectedResults[data.detect_id].identitySearch = false
              if (!this.expectedResults[data.detect_id].identitySearch && !this.expectedResults[data.detect_id].audioIntelligence) {
                delete this.expectedResults[data.detect_id]
              }
            }
          } else {
            if (!this.pendingUpdates[data.detect_id]) {
              this.pendingUpdates[data.detect_id] = {}
            }
            this.pendingUpdates[data.detect_id].identitySearch = data.identity_search
          }
        }
        break
      }
      case 'update_audio_intelligence': {
        if (data.detect_id && data.audio_intelligence) {
          const detectElement = document.getElementById(`detect-${data.detect_id}`)
          if (detectElement) {
            const detect = JSON.parse(detectElement.dataset.detect || '{}')
            detect.audio_intelligence = data.audio_intelligence
            this.updateDetectUI(detect)

            // Clear expectation for audio intelligence
            if (this.expectedResults[data.detect_id]) {
              this.expectedResults[data.detect_id].audioIntelligence = false
              if (!this.expectedResults[data.detect_id].identitySearch && !this.expectedResults[data.detect_id].audioIntelligence) {
                delete this.expectedResults[data.detect_id]
              }
            }
          } else {
            if (!this.pendingUpdates[data.detect_id]) {
              this.pendingUpdates[data.detect_id] = {}
            }
            this.pendingUpdates[data.detect_id].audioIntelligence = data.audio_intelligence
          }
        }
        break
      }
    }
  }

  applyPendingUpdates(detectId: string): void {
    if (this.pendingUpdates[detectId]) {
      const detectElement = document.getElementById(`detect-${detectId}`)
      if (detectElement) {
        const detect = JSON.parse(detectElement.dataset.detect || '{}')
        if (this.pendingUpdates[detectId].identitySearch) {
          detect.identity_search = this.pendingUpdates[detectId].identitySearch
        }
        if (this.pendingUpdates[detectId].audioIntelligence) {
          detect.audio_intelligence = this.pendingUpdates[detectId].audioIntelligence
        }
        this.updateDetectUI(detect)
      }
      delete this.pendingUpdates[detectId]
    }
  }

  updateDetectUI(detect: any): void {
    let detectElement = document.getElementById(`detect-${detect.uuid}`)
    const detectsList = document.getElementById('detects-list')

    if (!detectElement) {
      detectElement = document.createElement('li')
      detectElement.id = `detect-${detect.uuid}`
      detectElement.className = 'tw-flex tw-flex-col tw-mb-4 tw-bg-white tw-rounded-lg tw-overflow-hidden tw-shadow-md tw-border-l-8'

      // Find the processing row
      const processingRow = detectsList?.querySelector('li:first-child.tw-border-blue-500')

      if (processingRow) {
        // Insert after the processing row
        processingRow.insertAdjacentElement('afterend', detectElement)
      } else {
        // If no processing row exists, prepend to the list
        detectsList?.prepend(detectElement)
      }
    }

    // Determine result label and border color based on media type
    const getResultInfo = () => {
      switch (detect.media_type) {
        case 'video': {
          // Check if either metrics are null/undefined
          if (!detect.metrics || !detect.video_metrics) {
            return {
              borderColor: 'tw-border-blue-500',
              label: 'Processing',
              videoLabel: 'Processing',
            }
          }

          const audioLabel = detect.metrics?.certainty === -1 ? 'No Audio Stream' : (detect.metrics?.label || 'Processing').toLowerCase()
          const videoLabel = (detect.video_metrics?.label || 'Processing').toLowerCase()

          // Determine border color based on both conclusions
          const getBorderColor = (audioLabel: string, videoLabel: string) => {
            if (audioLabel === 'processing' || videoLabel === 'processing') return 'tw-border-blue-500'
            if (audioLabel === 'no audio stream') return 'tw-border-gray-500'
            if (audioLabel === 'real' && videoLabel === 'real') return 'tw-border-green-500'
            if (audioLabel === 'fake' || videoLabel === 'fake') return 'tw-border-red-500'
            if (audioLabel.includes('likely') || videoLabel.includes('likely')) return 'tw-border-yellow-500'
            return 'tw-border-gray-500' // For neutral/uncertain
          }

          return {
            borderColor: getBorderColor(audioLabel, videoLabel),
            label: detect.metrics?.certainty === -1 ? 'No Audio Stream' : detect.metrics?.label || 'Processing',
            videoLabel: detect.video_metrics?.label || 'Processing',
          }
        }
        case 'image': {
          if (!detect.image_metrics?.label) {
            return {
              borderColor: 'tw-border-blue-500',
              label: 'Processing',
            }
          }

          const label = detect.image_metrics.label.toLowerCase()
          let borderColor = 'tw-border-blue-500'

          switch (label) {
            case 'real':
              borderColor = 'tw-border-green-500'
              break
            case 'likely real':
              borderColor = 'tw-border-green-300'
              break
            case 'neutral/uncertain':
              borderColor = 'tw-border-gray-500'
              break
            case 'likely fake':
              borderColor = 'tw-border-red-300'
              break
            case 'fake':
              borderColor = 'tw-border-red-500'
              break
          }

          return {
            borderColor,
            label: detect.image_metrics.label,
          }
        }
        default: {
          const isAudioReal = detect.metrics?.label === 'real'
          return {
            borderColor: isAudioReal ? 'tw-border-green-500' : 'tw-border-red-500',
            label: detect.metrics?.label === 'real' ? 'Real' : 'Fake',
          }
        }
      }
    }

    const result = getResultInfo()
    detectElement.classList.remove('tw-border-green-500', 'tw-border-red-500')
    detectElement.classList.add(result.borderColor)

    const expectations = this.expectedResults[detect.uuid] || { identitySearch: false, audioIntelligence: false }
    const isProcessingAdditionalInfo = (expectations.identitySearch && !detect.identity_search) || (expectations.audioIntelligence && !detect.audio_intelligence)
    const hasAdditionalInfo = detect.identity_search || detect.audio_intelligence

    // Generate media button based on type
    const mediaButton = (() => {
      switch (detect.media_type) {
        case 'video':
          return `
            <button data-action="click->detect#showVideoModal" 
                    data-url="${detect.url || detect.audio_url}" 
                    class="tw-mr-4 tw-bg-green-500 tw-p-3 tw-rounded-full hover:tw-bg-green-600 tw-text-white tw-text-xl tw-align-center">
              <i class="fas fa-video"></i>
            </button>`
        case 'image':
          return `
            <button data-action="click->detect#showImageModal" 
                    data-url="${detect.url || detect.audio_url}" 
                    class="tw-mr-4 tw-bg-green-500 tw-p-3 tw-rounded-full hover:tw-bg-green-600 tw-text-white tw-text-xl tw-align-center">
              <i class="fas fa-image"></i>
            </button>`
        default:
          return `
            <button data-action="click->detect#togglePlayStop" 
                    data-index="${detect.uuid}" 
                    class="tw-mr-4 tw-bg-green-500 tw-p-3 tw-rounded-full hover:tw-bg-green-600 tw-text-white tw-text-xl tw-align-center">
              <i class="fas fa-play-circle"></i>
            </button>`
      }
    })()

    detectElement.innerHTML = `
      <div class="tw-flex tw-items-center tw-justify-between tw-p-4">
        <div class="tw-flex tw-items-center">
          ${mediaButton}
          <div>
            <span class="tw-font-bold tw-truncate">${detect.filename || this.filename || 'Unknown file'}</span><br>
            <span class="tw-text-gray-600">${new Date(detect.created_at).toLocaleString()}</span>
          </div>
        </div>
        <div class="tw-flex tw-items-center">
          <a href="/report/${detect.uuid}" class="tw-bg-blue-500 tw-text-white tw-rounded tw-px-2 tw-py-1 tw-mr-2" target="_blank">
            <i class="fas fa-file-alt"></i> Report
          </a>
          ${
            detect.media_type === 'video'
              ? `<span class="tw-bg-${detect.metrics?.certainty === -1 ? 'gray' : detect.metrics?.label === 'real' ? 'green' : 'red'}-500 tw-text-white tw-rounded tw-px-2 tw-py-1 tw-mr-2">${detect.metrics?.certainty === -1 ? 'No Audio Stream' : detect.metrics?.label || 'Processing'} Audio</span>
                 <span class="tw-bg-${detect.video_metrics?.label ? (detect.video_metrics.label.toLowerCase() === 'real' ? 'green' : 'red') : 'blue'}-500 tw-text-white tw-rounded tw-px-2 tw-py-1 tw-mr-2">${detect.video_metrics?.label || 'Processing'} Video</span>`
              : `<span class="tw-bg-${result.label === 'Real' ? 'green' : 'red'}-500 tw-text-white tw-rounded tw-px-2 tw-py-1 tw-mr-2">${result.label}</span>`
          }
          ${detect.media_type !== 'image' ? `<span class="tw-bg-gray-200 tw-rounded tw-px-2 tw-py-1 tw-mr-4">${detect.duration == -1 ? 'N/A' : detect.duration} seconds</span>` : ''}
          <a href="/detect/${detect.uuid}" data-turbo-method="delete" data-turbo-confirm="Are you sure?" class="tw-text-gray-500 hover:tw-text-gray-700">
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>

      ${detect.media_type === 'audio' ? `<audio id="audio_${detect.uuid}" src="${detect.audio_url || this.audio_url}"></audio>` : ''}

      ${
        isProcessingAdditionalInfo
          ? `<div class="tw-p-4">
            <span class="tw-text-blue-500 tw-flex tw-items-center">
              <i class="fas fa-spinner fa-spin tw-mr-2"></i> Loading additional information...
            </span>
          </div>`
          : hasAdditionalInfo
            ? `<div class="tw-p-4">
              <button data-action="click->detect#toggleDetails" data-detect-id="${detect.uuid}" 
                      class="details-toggle tw-text-blue-500 tw-cursor-pointer tw-underline tw-flex tw-items-center"
                      aria-expanded="false" aria-controls="details-${detect.uuid}">
                Details
                <i class="fas fa-chevron-down tw-ml-1"></i>
              </button>
              <div id="details-${detect.uuid}" class="details-section">
                ${detect.identity_search ? this.renderIdentitySearch(detect.identity_search) : ''}
                ${detect.audio_intelligence ? this.renderAudioIntelligence(detect.audio_intelligence) : ''}
              </div>
            </div>`
            : ''
      }

      ${this.renderVisualization(detect)}
    `

    // Reconnect Stimulus controller
    this.application.getControllerForElementAndIdentifier(detectElement, 'detect')?.disconnect()
    this.application.getControllerForElementAndIdentifier(detectElement, 'detect')?.connect()

    detectElement.dataset.detect = JSON.stringify(detect)
    this.applyPendingUpdates(detect.uuid)
  }

  private renderVisualization(detect: any): string {
    if (detect.media_type === 'image' && detect.image_metrics?.label) {
      const isReal = detect.image_metrics.label === 'real'
      const bgColor = isReal ? 'tw-bg-emerald-500' : 'tw-bg-red-500'
      return `
        <div class="tw-h-1 tw-rounded tw-overflow-hidden tw-w-full tw-flex">
          <div style="width: 100%;" class="${bgColor} tw-h-full"></div>
        </div>
      `
    } else if (detect.metrics?.score) {
      return `
        <div class="tw-h-1 tw-rounded tw-overflow-hidden tw-w-full tw-flex">
          ${detect.metrics.score
            .map((score: number) => {
              const isReal = score < 0.5
              const shade = isReal ? (score < 0.1 ? 200 : score < 0.2 ? 400 : score < 0.3 ? 600 : score < 0.4 ? 800 : 900) : Math.ceil(score * 5) * 100
              const bgColor = isReal ? `tw-bg-emerald-${shade}` : `tw-bg-red-${shade}`
              return `<div style="width: ${100.0 / detect.metrics.score.length}%;" class="${bgColor} tw-h-full"></div>`
            })
            .join('')}
        </div>
      `
    }
    return ''
  }

  updateIdentitySearchUI(detectId: string, identitySearch: any) {
    const detailsContainer = document.getElementById(`details-${detectId}`)
    if (detailsContainer) {
      const identitySearchHtml = this.renderIdentitySearch(identitySearch)
      detailsContainer.innerHTML = identitySearchHtml + detailsContainer.innerHTML
    }
  }

  updateAudioIntelligenceUI(detectId: string, audioIntelligence: any) {
    const detailsContainer = document.getElementById(`details-${detectId}`)
    if (detailsContainer) {
      const audioIntelligenceHtml = this.renderAudioIntelligence(audioIntelligence)
      detailsContainer.innerHTML += audioIntelligenceHtml
    }
  }

  renderIdentitySearch(identitySearch: any): string {
    if (!identitySearch || !identitySearch.results) {
      return ''
    }

    const sortedResults = Object.entries(identitySearch.results)
      .sort(([, a], [, b]) => (b as any).distance - (a as any).distance)
      .slice(0, 3)

    const resultHtml = sortedResults
      .map(
        ([, identity]) => `
      <div class="tw-mb-2">
        <div class="tw-flex tw-justify-between tw-items-center tw-mb-1">
          <span class="tw-font-medium">${(identity as any).name}</span>
          <span class="tw-font-bold">${Math.max(0, Math.min((identity as any).distance, 100)).toFixed(2)}%</span>
        </div>
        <div class="tw-bg-gray-300 tw-h-2 tw-rounded-full">
          <div class="tw-bg-slate-500 tw-h-full tw-rounded-full" style="width: ${Math.max(0, Math.min((identity as any).distance, 100))}%;"></div>
        </div>
      </div>
    `,
      )
      .join('')

    return `
      <h3 class="tw-font-bold tw-text-lg tw-mb-2">Identity:</h3>
      <div class="tw-bg-gray-100 tw-p-4 tw-rounded-lg tw-mb-4">
        ${resultHtml}
      </div>
    `
  }

  renderAudioIntelligence(audioIntelligence: any): string {
    if (!audioIntelligence || !audioIntelligence.description) {
      return ''
    }

    // This function should be implemented in your JavaScript to match
    // the Ruby helper method's functionality
    const formattedDescription = this.formatAudioIntelligence(audioIntelligence.description)

    return `
      <h3 class="tw-font-bold tw-text-lg tw-mb-2">Audio Intelligence:</h3>
      <div class="tw-bg-gray-100 tw-p-4 tw-rounded-lg tw-text-sm audio-intelligence">
        ${formattedDescription}
      </div>
    `
  }

  // Helper method to format audio intelligence description
  formatAudioIntelligence(text: string): string {
    // Convert **bold** to <strong>bold</strong>
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')

    // Convert newlines to paragraph tags
    text = '<p>' + text.replace(/\n\n+/g, '</p><p>') + '</p>'

    // Convert single newlines within paragraphs to <br> tags
    text = text.replace(/([^\n])\n([^\n])/g, '$1<br>$2')

    // Convert bullet points to list items
    text = text.replace(/\* (.*)/g, '<li>$1</li>')

    // Wrap lists in <ul> tags
    text = text.replace(/(<li>.*<\/li>)/g, '<ul>$1</ul>')

    // Convert single-line headers (assuming they use : for headers)
    text = text.replace(/^(.*?):/, '<h4>$1:</h4>')

    return text
  }

  handleFileInputChange(event: Event): void {
    if (event.target instanceof HTMLInputElement && event.target.files) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0]
        const fileName = file.name
        this.fileNameTarget.textContent = fileName

        // Update placeholder text based on file type
        let fileType = 'file'
        if (file.type.startsWith('audio/')) fileType = 'audio'
        else if (file.type.startsWith('video/')) fileType = 'video'
        else if (file.type.startsWith('image/')) fileType = 'image'

        // Update icon based on file type
        const iconElement = this.fileInputWrapperTarget.querySelector('i')
        if (iconElement) {
          iconElement.className = `fas ${this.getFileTypeIcon(fileType)} tw-text-xl tw-mb-3`
        }

        // Toggle advanced settings based on file type
        this.toggleAdvancedSettingsForFileType(fileType)

        this.fileInputWrapperTarget.classList.add('tw-border-emerald-500')
        this.fileInputWrapperTarget.classList.remove('tw-border-gray-300')

        const isAudio = file.type.startsWith('audio/')

        if (isAudio) {
          this.videoSettingsMessageTarget.classList.remove('tw-hidden')
          this.videoSettingsContentTarget.classList.add('tw-opacity-50')
          this.videoSettingsInputsTargets.forEach((input) => {
            if (input instanceof HTMLInputElement) {
              input.disabled = true
            }
          })
        } else {
          this.videoSettingsMessageTarget.classList.add('tw-hidden')
          this.videoSettingsContentTarget.classList.remove('tw-opacity-50')
          this.videoSettingsInputsTargets.forEach((input) => {
            if (input instanceof HTMLInputElement) {
              input.disabled = false
            }
          })
        }
      } else {
        this.resetFileInput()
      }
    }
  }

  private getFileTypeIcon(fileType: string): string {
    switch (fileType) {
      case 'audio':
        return 'fa-music'
      case 'video':
        return 'fa-video'
      case 'image':
        return 'fa-image'
      default:
        return 'fa-upload'
    }
  }

  private resetFileInput(): void {
    this.fileNameTarget.textContent = 'Upload your media (Audio, Video, or Image)'
    const iconElement = this.fileInputWrapperTarget.querySelector('i')
    if (iconElement) {
      iconElement.className = 'fas fa-upload tw-text-xl tw-mb-3'
    }
    this.fileInputWrapperTarget.classList.remove('tw-border-emerald-500')
    this.fileInputWrapperTarget.classList.add('tw-border-gray-300')

    // Re-enable advanced settings
    this.toggleAdvancedSettingsForFileType('audio')
  }

  private toggleAdvancedSettingsForFileType(fileType: string): void {
    const isImage = fileType === 'image'
    const isVideo = fileType === 'video'

    // Get video settings section
    const videoSettings = this.element.querySelector('[data-detect-target="videoSettings"]') as HTMLElement

    // Show/hide video settings based on file type
    if (videoSettings) {
      videoSettings.classList.toggle('tw-hidden', !isVideo)
    }

    // Disable all advanced settings inputs for images
    this.advancedSettingsInputsTargets.forEach((input) => {
      if (input instanceof HTMLInputElement) {
        input.disabled = isImage

        // Reset values if disabled
        if (isImage) {
          if (input.type === 'range') {
            input.value = input.defaultValue
            // Update corresponding span if it exists
            const spanId = input.dataset.spanId
            if (spanId) {
              const span = document.getElementById(spanId)
              if (span) {
                span.innerText = input.value + (spanId === 'sensitivityValue' ? '%' : '')
              }
            }
          } else if (input.type === 'checkbox') {
            input.checked = false
            // Reset toggle switch appearance
            const toggleSwitch = input.parentElement?.querySelector('.toggle-switch div') as HTMLElement
            if (toggleSwitch) {
              toggleSwitch.style.transform = 'translateX(0)'
            }
            const toggleBg = input.nextElementSibling
            if (toggleBg) {
              toggleBg.classList.remove('tw-bg-green-500')
              toggleBg.classList.add('tw-bg-gray-200')
            }
          }
        }
      }
    })

    // Add visual indication that settings are disabled for images
    const advancedSettingsSection = this.advancedSettingsTarget
    if (isImage) {
      advancedSettingsSection.classList.add('tw-opacity-50')
      // Add a message explaining why settings are disabled
      const existingMessage = advancedSettingsSection.querySelector('.settings-disabled-message')
      if (!existingMessage) {
        const message = document.createElement('div')
        message.className = 'settings-disabled-message tw-text-sm tw-text-gray-500 tw-mb-6 tw-p-3 tw-bg-gray-50 tw-rounded-md tw-border tw-border-gray-200'
        message.textContent = 'Advanced settings are not available for image files'
        advancedSettingsSection.prepend(message)
      }
    } else {
      advancedSettingsSection.classList.remove('tw-opacity-50')
      const message = advancedSettingsSection.querySelector('.settings-disabled-message')
      if (message) {
        message.remove()
      }
    }
  }

  handleFormSubmit(e: Event): void {
    e.preventDefault()

    const formData = new FormData(this.formTarget)
    const file = formData.get('audio') as File
    if (file.size === 0) {
      return
    }

    // Delete the settings if they are not changed
    Object.keys(this.settingsChanged).forEach((key) => {
      if (this.settingsChanged[key as keyof typeof this.settingsChanged] === false) {
        formData.delete(key)
      }
    })

    this.filename = file.name
    this.audio_url = URL.createObjectURL(file)

    // Set expectations for identity search and audio intelligence
    const expectIdentitySearch = formData.get('search_identity') === '1'
    const expectAudioIntelligence = formData.get('audio_intelligence') === '1'

    this.openModal()
    fetch(this.formTarget.action, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data: { success: boolean; item?: { uuid: string }; errors?: Record<string, string[]>; message?: string }) => {
        if (data.success && typeof data.item?.uuid === 'string') {
          this.expectedResults[data.item.uuid] = {
            identitySearch: expectIdentitySearch,
            audioIntelligence: expectAudioIntelligence,
          }
          this.pollForMetrics(data.item.uuid)
        } else if (data.errors) {
          // Handle validation errors
          this.closeModal()
          const errorMessages = Object.entries(data.errors)
            .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
            .join('\n')
          Swal.fire('Validation Error', errorMessages, 'error')
        } else {
          console.error('Error in processing: ', data.message)
          this.closeModal()
          Swal.fire('Error', data.message || 'Failed to process the audio!', 'error')
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        this.closeModal()
        Swal.fire('Error', 'Something went wrong. Please try again', 'error')
      })
  }

  pollForMetrics(uuid: string): void {
    const pollInterval = 3000
    let lastAudioLabel: string | undefined
    let lastVideoLabel: string | undefined

    const pollFunction = () => {
      fetch(`/api/v2/detect/${uuid}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.success && data.item) {
            let hasProcessed = false

            if (data.item.media_type === 'video') {
              // Check if either metric has updated since last poll
              if (data.item.metrics?.label !== lastAudioLabel || data.item.video_metrics?.label !== lastVideoLabel) {
                // Update UI with current state
                this.handleWebSocketMessage({ action: 'update_detect', detect: data.item })

                // Update last known states
                lastAudioLabel = data.item.metrics?.label
                lastVideoLabel = data.item.video_metrics?.label
              }

              // Only consider fully processed when both metrics exist
              hasProcessed = !!(data.item.metrics?.label && data.item.video_metrics?.label)
            } else if (data.item.media_type === 'image') {
              hasProcessed = !!data.item.image_metrics
              if (hasProcessed) {
                this.handleWebSocketMessage({ action: 'update_detect', detect: data.item })
              }
            } else {
              hasProcessed = !!data.item.metrics?.label
              if (hasProcessed) {
                this.handleWebSocketMessage({ action: 'update_detect', detect: data.item })
              }
            }

            if (hasProcessed) {
              this.closeModal()
            } else {
              setTimeout(pollFunction, pollInterval)
            }
          } else {
            setTimeout(pollFunction, pollInterval)
          }
        })
        .catch(() => {
          this.closeModal()
        })
    }
    pollFunction()
  }

  openModal(): void {
    document.body.classList.add('tw-bg-gray-500')
    this.modalTarget.classList.remove('tw-hidden')
    this.modalTarget.classList.add('tw-flex')
  }

  closeModal(): void {
    document.body.classList.remove('tw-bg-gray-500')
    this.modalTarget.classList.remove('tw-flex')
    this.modalTarget.classList.add('tw-hidden')
  }

  updateWithSlider(event: Event): void {
    const target = event.target as HTMLInputElement
    if (!target) return

    const value = parseFloat(target.value)
    const spanElement = document.getElementById(target.dataset.spanId || '')
    if (!spanElement) return

    // If value is greater than -1 but less than 0, snap back to -1
    if (value > -1 && value < 0) {
      target.value = '-1'
      spanElement.textContent = '-1'
      return
    }

    // If value is greater than 0, round to nearest 0.01
    if (value > 0) {
      const roundedValue = Math.round(value * 100) / 100
      target.value = roundedValue.toString()
      spanElement.textContent = roundedValue.toString()
      return
    }

    // If value is -1, keep it as is
    spanElement.textContent = value.toString()
  }

  toggleAdvancedSettings(): void {
    const advancedSettings = this.advancedSettingsTarget
    const icon = this.advancedSettingsIconTarget as HTMLElement // Ensure you declare this target in your controller
    advancedSettings.classList.toggle('tw-hidden')
    if (advancedSettings.classList.contains('tw-hidden')) {
      icon.classList.remove('fa-chevron-up')
      icon.classList.add('fa-chevron-down')
    } else {
      icon.classList.remove('fa-chevron-down')
      icon.classList.add('fa-chevron-up')
    }
  }

  toggleVoiceIsolation(): void {
    const toggleBg = this.checkboxTarget.nextElementSibling
    this.checkboxTarget.checked = !this.checkboxTarget.checked
    if (this.checkboxTarget.checked) {
      this.toggleSwitchTarget.style.transform = 'translateX(90%)'
      toggleBg?.classList.remove('tw-bg-gray-200')
      toggleBg?.classList.add('tw-bg-green-500')
    } else {
      this.toggleSwitchTarget.style.transform = 'translateX(0)'
      toggleBg?.classList.remove('tw-bg-green-500')
      toggleBg?.classList.add('tw-bg-gray-200')
    }
  }

  toggleSearchIdentity(): void {
    const toggleBg = this.searchIdentityCheckboxTarget.nextElementSibling
    this.searchIdentityCheckboxTarget.checked = !this.searchIdentityCheckboxTarget.checked
    if (this.searchIdentityCheckboxTarget.checked) {
      this.searchIdentityToggleSwitchTarget.style.transform = 'translateX(90%)'
      toggleBg?.classList.remove('tw-bg-gray-200')
      toggleBg?.classList.add('tw-bg-green-500')
      this.settingsChanged.search_identity = true
    } else {
      this.searchIdentityToggleSwitchTarget.style.transform = 'translateX(0)'
      toggleBg?.classList.remove('tw-bg-green-500')
      toggleBg?.classList.add('tw-bg-gray-200')
      this.settingsChanged.search_identity = false
    }
  }

  toggleAudioIntelligence(): void {
    const toggleBg = this.audioIntelligenceCheckboxTarget.nextElementSibling
    this.audioIntelligenceCheckboxTarget.checked = !this.audioIntelligenceCheckboxTarget.checked
    if (this.audioIntelligenceCheckboxTarget.checked) {
      this.audioIntelligenceToggleSwitchTarget.style.transform = 'translateX(90%)'
      toggleBg?.classList.remove('tw-bg-gray-200')
      toggleBg?.classList.add('tw-bg-green-500')
      this.settingsChanged.audio_intelligence = true
    } else {
      this.audioIntelligenceToggleSwitchTarget.style.transform = 'translateX(0)'
      toggleBg?.classList.remove('tw-bg-green-500')
      toggleBg?.classList.add('tw-bg-gray-200')
      this.settingsChanged.audio_intelligence = false
    }
  }

  toggleDetails(event: Event) {
    const detailsId = (event.currentTarget as HTMLElement).dataset.detectId
    const detailsElement = document.getElementById(`details-${detailsId}`)
    const toggleButton = event.currentTarget

    if (detailsElement) {
      detailsElement.classList.toggle('expanded')
      const isExpanded = detailsElement.classList.contains('expanded')
      if (toggleButton instanceof HTMLElement) {
        toggleButton.classList.toggle('expanded')
        toggleButton.setAttribute('aria-expanded', isExpanded.toString())
      }
    }
  }

  togglePlayStop(event: Event): void {
    const clickTarget = event.target as HTMLElement
    const button = clickTarget?.closest('button') as HTMLButtonElement // click could be on the icon or the button

    const index = button.dataset.index
    const audio = document.getElementById(`audio_${index}`) as HTMLAudioElement
    const playIcon = button.querySelector('i')

    if (audio.paused) {
      audio.play()
      playIcon?.classList.remove('fa-play-circle')
      playIcon?.classList.add('fa-stop-circle')
      button.classList.add('tw-bg-green-600')
      button.classList.remove('tw-bg-green-500')
    } else {
      audio.pause()
      audio.currentTime = 0
      playIcon?.classList.add('fa-play-circle')
      playIcon?.classList.remove('fa-stop-circle')
      button.classList.remove('tw-bg-green-600')
      button.classList.add('tw-bg-green-500')
    }
  }

  updateUIWithMetrics(detectData: any): void {
    this.updateDetectUI(detectData)
    // window.location.reload()
  }

  showImageModal(event: Event): void {
    const button = event.currentTarget as HTMLElement
    const imageUrl = button.dataset.url
    if (!imageUrl) return

    const modal = document.createElement('div')
    modal.className = 'tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-75'
    modal.innerHTML = `
      <div class="tw-bg-white tw-rounded-lg tw-p-4 tw-max-w-4xl tw-max-h-[90vh] tw-overflow-auto">
        <img src="${imageUrl}" alt="Detected Image" class="tw-max-w-full tw-h-auto">
        <button class="tw-mt-4 tw-bg-gray-500 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-gray-600">Close</button>
      </div>
    `

    modal.addEventListener('click', (e) => {
      if (e.target === modal || (e.target as HTMLElement).closest('button')) {
        modal.remove()
      }
    })

    document.body.appendChild(modal)
  }

  showVideoModal(event: Event): void {
    const button = event.currentTarget as HTMLElement
    const videoUrl = button.dataset.url
    if (!videoUrl) return

    const modal = document.createElement('div')
    modal.className = 'tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-75'
    modal.innerHTML = `
      <div class="tw-bg-white tw-rounded-lg tw-p-4 tw-max-w-4xl tw-max-h-[90vh] tw-overflow-auto">
        <video controls class="tw-max-w-full tw-h-auto">
          <source src="${videoUrl}" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <button class="tw-mt-4 tw-bg-gray-500 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-gray-600">Close</button>
      </div>
    `

    modal.addEventListener('click', (e) => {
      if (e.target === modal || (e.target as HTMLElement).closest('button')) {
        const video = modal.querySelector('video')
        if (video) {
          video.pause()
        }
        modal.remove()
      }
    })

    document.body.appendChild(modal)
  }

  toggleLLM(): void {
    const toggleBg = this.llmCheckboxTarget.nextElementSibling
    this.llmCheckboxTarget.checked = !this.llmCheckboxTarget.checked
    if (this.llmCheckboxTarget.checked) {
      this.llmToggleSwitchTarget.style.transform = 'translateX(90%)'
      toggleBg?.classList.remove('tw-bg-gray-200')
      toggleBg?.classList.add('tw-bg-green-500')
      this.settingsChanged.use_llm = true
    } else {
      this.llmToggleSwitchTarget.style.transform = 'translateX(0)'
      toggleBg?.classList.remove('tw-bg-green-500')
      toggleBg?.classList.add('tw-bg-gray-200')
      this.settingsChanged.use_llm = false
    }
  }
}
